import React from "react";
import Content from '../views/UseCases';

export default ({location}) => (
  <Content location={location} seo={{
    title: "Use cases of how connected car services can be used.",
    lang: 'en',
    description: "The diversity of use cases for how connected vehicle services can be used: remote vehicle monitoring, preventive maintenance, ev management, etc.",
    meta: [{
      name: 'keywords',
      content: 'Connected vehicle use cases',
    }]
  }}/>
);